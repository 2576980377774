@font-face {
  font-family: Source Sans Pro;
  src: url(../fonts/sourcesanspro-it-webfont.eot);
  src: url(../fonts/sourcesanspro-it-webfont.eot?#iefix) format("embedded-opentype"), url(../fonts/sourcesanspro-it-webfont.woff2) format("woff2"), url(../fonts/sourcesanspro-it-webfont.woff) format("woff"), url(../fonts/sourcesanspro-it-webfont.ttf) format("truetype"), url(../fonts/sourcesanspro-it-webfont.svg#source_sans_proitalic) format("svg");
  font-weight: 400;
  font-style: italic
}

@font-face {
  font-family: Source Sans Pro;
  src: url(../fonts/sourcesanspro-bold-webfont.eot);
  src: url(../fonts/sourcesanspro-bold-webfont.eot?#iefix) format("embedded-opentype"), url(../fonts/sourcesanspro-bold-webfont.woff2) format("woff2"), url(../fonts/sourcesanspro-bold-webfont.woff) format("woff"), url(../fonts/sourcesanspro-bold-webfont.ttf) format("truetype"), url(../fonts/sourcesanspro-bold-webfont.svg#source_sans_probold) format("svg");
  font-weight: 700;
  font-style: normal
}

@font-face {
  font-family: Source Sans Pro;
  src: url(../fonts/sourcesanspro-regular-webfont.eot);
  src: url(../fonts/sourcesanspro-regular-webfont.eot?#iefix) format("embedded-opentype"), url(../fonts/sourcesanspro-regular-webfont.woff2) format("woff2"), url(../fonts/sourcesanspro-regular-webfont.woff) format("woff"), url(../fonts/sourcesanspro-regular-webfont.ttf) format("truetype"), url(../fonts/sourcesanspro-regular-webfont.svg#source_sans_proregular) format("svg");
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: Open Sans;
  src: url('../fonts/Open Sans regular.ttf');
  src: url('../fonts/Open Sans regular.ttf') format("truetype");
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: Open Sans;
  src: url('../fonts/Open Sans italic.ttf');
  src: url('../fonts/Open Sans italic.ttf') format("truetype");
  font-weight: 400;
  font-style: italic
}

@font-face {
  font-family: Open Sans;
  src: url('../fonts/Open Sans 700.ttf');
  src: url('../fonts/Open Sans 700.ttf') format("truetype");
  font-weight: 700;
  font-style: normal
}
