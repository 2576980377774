// Use this file to overwrite the basic Bootstrap variables and add your own variables
// To overwrite a Bootstrap variable you don´t have to touch the Bootstrap folder.
// Just copy a variable from src/sass/bootstrap4/_variables.scss, paste it here and edit the value.

$enable-responsive-font-sizes: true;

$btn-border-radius: 6px;
$btn-padding-x: 2;
$btn-padding-y: 1;

$font-size-base: 1.125rem;
$font-family-base: "Open Sans", sans-serif;
$headings-color: #212529;
$text-muted: #908f88;
$headings-font-family: "Source Sans Pro", sans-serif;
$headings-font-weight: 600;
$lead-font-weight: 400;
$lead-font-size: 24;
$font-weight-bolder: 600;

/* Card */
$card-border-radius: 6px;
$card-spacer-x: 1.5rem;
$card-bg: #f5f3ee;
$card-border-width: 0;

/* List group */
$list-group-bg: transparent;
$list-group-border-color: #e6e6e6;
$list-group-hover-bg: #e3e4de;
$list-group-focus-bg: #d2d3cd;
$list-group-action-active-bg: #D2D3CD;
$list-group-item-padding-x: 2rem;


/* Paragraph */
$line-height-base: 1.618;

$navbar-dark-color: rgba(255, 255, 255, 1.0);
$navbar-dark-toggler-border-color: rgba(255, 255, 255, .75);
$navbar-light-active-color: rgba(255, 255, 255, .9);
$navbar-light-color: rgba(255, 255, 255, 1);
$navbar-light-disabled-color: rgba(255, 255, 255, .3);
$navbar-light-hover-color: rgba(255, 255, 255, .7);
$navbar-light-toggler-border-color: rgba(255, 255, 255, .1);
$navbar-padding-x: 1rem;
$nav-link-padding-x: 0;
$nav-link-padding-y: .25rem;
$navbar-padding-y: 0rem;
$navbar-dark-toggler-border-color: transparent;
$navbar-toggler-font-size: .75rem;
$navbar-toggler-padding-x: .5rem;
$navbar-toggler-padding-y: .5rem;

$primary: #00529D;

$container-max-widths: (
	sm: 100%,
	md: 100%,
	lg: 960px,
	xl: 1200px
) !default;
$grid-gutter-width: 48px;

$btn-padding-y: .5rem;
$btn-padding-x: 1rem;
